import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import Grid from "@toast-ui/react-grid";
import { Form} from "react-bootstrap";
import api from "../../CustomAxios";
import LinkInGrid from '../utils/linkInGrid';
import { useNavigate, useParams } from "react-router-dom";
import TuiGrid from 'tui-grid';
import ButtonInGrid from '../utils/buttonInGrid';
import { alert, confirm } from 'react-bootstrap-confirmation';
import { getTreeFromFlatData, } from '@nosferatu500/react-sortable-tree';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare ,faChevronRight,faChevronDown,faPlusSquare,faMinusSquare,faFolder,faFolderOpen,faFile} from "@fortawesome/free-solid-svg-icons";
import { faSquare } from '@fortawesome/free-regular-svg-icons';

function withRouter(Component){
	function ComponentWithRouterProp(props){
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component {...props} router={{navigate, params}}/>
		);
	}
	return ComponentWithRouterProp
}

const capitalize = (text) =>{
    return text.charAt(0).toUpperCase() + text.slice(1);
}


class Authority extends Component {
	constructor(props){
		super(props);
		this.state= {
            sideValue: "",
            sideData: [],
            role: "",
            authData: [],
            roles: [],
            originTreeData: [],
            treeData: [],

            _GROUP_ID: sessionStorage.getItem("_GROUP_ID"),
		}
	}

    authRef = React.createRef();
    sideRef = React.createRef();
    
    componentDidMount = () => {
        this.getSideSelect();
        this.getAuth();
    }

    getSideSelect = () => {
        this.setState({sideData: [], treeData: []});
        if(this.state.sideValue === "menu") {
            this.getMenu();
        }

        if(this.state.sideValue === "brand") {
            this.getBrand();
        }

        if(this.state.sideValue === "category") {
            this.getCategory();
        }
    }

    getAuth = () => {
        const params = {};
		params.code = "";
        params.searchKeyword = "";
        api.post(process.env.REACT_APP_DB_HOST + "/common/getUserGroups", params).then(res=>{
			if(res.status === 200){
				this.setState({
					roles : res.data
				});
			}
		})
    }

    getMenu = async() => {
        try{
            const res1 = await api.get(process.env.REACT_APP_DB_HOST+"/common/menu");
            const res2 = await api.get(process.env.REACT_APP_DB_HOST+"/common/auth/mapping", {
                params : {
                    "groupId" : this.state.role, 
                    "authMapType": "menu"
                }
            });
            const data = res1.data;
            const newFlatData = [];
            for(let i in data){
                newFlatData.push({
                    value : data[i].menuNo,
                    label : data[i].menuTitle,
                    parentId : data[i].upperMenuNo === 0 ? null : data[i].upperMenuNo,
                    new : false,
                    expanded : true
                });
            }
            
            const treeData = getTreeFromFlatData({
                flatData : newFlatData,
                getKey : node => node.value,
                getParentKey : node => node.parentId,
                rootKey : null
            });
            
            const checked = [];
            for(let i in res2.data){
                checked.push(res2.data[i].rnrNo)
            }
            
            this.setState({
                originTreeData: data, 
                treeData : treeData,
                checked : checked,
                authData: res2.data
            });
        }catch(err){
            if(err.response){
                console.log(err.response.data);
            }else if(err.request){
                console.log(err.request);
            }else{
                console.log('Error', err.message);
            }
        }
    }

    getCategory = async() => {
        try{
            const resCategory = await api.get(process.env.REACT_APP_DB_HOST+"/product/getCategories", {params: {isLast: false, groupId: this.state._GROUP_ID}});
            const resCategoryAuth = await api.get(process.env.REACT_APP_DB_HOST+"/common/auth/mapping", {
                                        params : {
                                            "groupId" : this.state.role,
                                            "authMapType" : "category"
                                        }
                                    });
            const newFlatData = [];
            
            for(let i in resCategory.data){
                newFlatData.push({
                    value: resCategory.data[i].categoryNo, 
                    label: resCategory.data[i].categoryName,
                    parentId: resCategory.data[i].upperCategoryNo === "0" ? null : resCategory.data[i].upperCategoryNo,
                    new: false,
                    expanded: true,                 
                });
            }

            const treeData = getTreeFromFlatData({
                flatData: newFlatData,
                getKey: node => node.value,
                getParentKey: node => node.parentId,
                rootKey: null
            });

            const checked = [];
            for(let i in resCategoryAuth.data){
                checked.push(resCategoryAuth.data[i].rnrNo);
            }
            
            this.setState({
                originTreeData: resCategory.data, 
                treeData: treeData,
                checked : checked,
                authData: resCategoryAuth.data
            });
        } catch(err){
            if(err.response){
                console.log(err.response.data);
            }else if(err.request){
                console.log(err.request);
            }else{
                console.log('Error', err.message);
            }
        }
    }

    getBrand = async() => {
        try{
            const res = await api.get(process.env.REACT_APP_DB_HOST+"/product/getBrands", {params : {groupId: this.state._GROUP_ID}});
            const brandAuth = await api.get(process.env.REACT_APP_DB_HOST+"/common/auth/mapping", {
                params : {
                    "groupId" : this.state.role,
                    "authMapType" : "brand"
                }
            });
            const data = res.data;
            for(let i in data){
                data[i].name = data[i].brandName;
                data[i].no = data[i].brandNo;
            }
            
            this.setState({
                sideData: data,
                authData: brandAuth.data
            });
        } catch(err) {
            if(err.response){
                console.log(err.response.data);
            }else if(err.request){
                console.log(err.request);
            }else{
                console.log('Error', err.message);
            }
        }
    }

    onChangeResponsibility = (e) => {
        this.setState({
            sideValue: e.target.value,
        }, () => {
            this.authRef.current.getInstance().resetData([]);
            this.getSideSelect();
        });
    }

    onChangeRole = (e) => {
        if(this.state.sideValue === ""){
            alert("권한을 설정할 기능을 선택해주세요.");
            return;
        }
        this.setState({role: e.target.value, roleName: e.target[e.target.selectedIndex].text});

        if(this.state.sideValue === "menu"){
            this.getMenu();
        }

        if(this.state.sideValue === "category"){
            this.getCategory();
        }

        if(this.state.sideValue === "brand"){
            this.getBrand();
        }
    }

    onSaveAuthority = (e) => {
        e.preventDefault();
        let rnrs = [];
        if(this.state.sideValue !== ("brand" && "" )){
            const temp = this.state.checked;
            const originTree = this.state.originTreeData;
            for(let i in temp){
                const param = originTree.filter(item => {
                    item.authMapType = this.state.sideValue;
                    item.groupId = this.state.role;
                    item.insertUser = sessionStorage.getItem("_USER_ID");
                    item.updateUser = sessionStorage.getItem("_USER_ID");
                    if(this.state.sideValue === "menu"){
                        if(Number(item.menuNo) === Number(temp[i])){
                            item.rnrNo = Number(item.menuNo);
                            return item;
                        }                            
                    }

                    if(this.state.sideValue === "category"){
                        if(Number(item.categoryNo) === Number(temp[i])){
                            item.rnrNo = Number(item.categoryNo);
                            return item;
                        }
                    }
                });
                rnrs.push(param[0]);
            }
        }

        if(this.state.sideValue === "brand"){
            rnrs = this.sideRef.current.getInstance().getCheckedRows();
            for(let i in rnrs){
                rnrs[i].authMapType = "brand";
                rnrs[i].groupId = this.state.role;
                rnrs[i].rnrNo = Number(rnrs[i].brandNo);
                rnrs[i].insertUser = sessionStorage.getItem("_USER_ID");
                rnrs[i].updateUser = sessionStorage.getItem("_USER_ID");
            }
        }

        api.post(process.env.REACT_APP_DB_HOST+"/common/auth/mapping", rnrs).then(res => {
            if(res.status === 200){
                alert("권한설정이 완료되었습니다.");
                this.authRef.current.getInstance().resetData(res.data);
            }
        }).catch(err => {
            if(err.response){
                console.log(err.response.data);
            }else if(err.request){
                console.log(err.request);
            }else{
                console.log('Error', err.message);
            }
        });
    }

    render () {
        const { treeData } = this.state;

        const onButtonClicked = async(grid, rowKey, buttonType)=>{
            const rowData = grid.getRow(rowKey);
            console.log(rowData);
            rowData.creator = sessionStorage.getItem("_USER_ID");
            rowData.updator = sessionStorage.getItem("_USER_ID");
            const confirmResult = await confirm("Are you sure to delete?");
            if(confirmResult){
                api.delete(process.env.REACT_APP_DB_HOST+"/common/auth/mapping/"+rowData.authMapNo).then(res => {
                    if(res.status === 200){
                        alert("Delete success");
                        grid.removeRow(rowKey);
                    }
                }).catch(err => {
                    if(err.response){
                        console.log(err.response.data);
                    }else if(err.request){
                        console.log(err.request);
                    }else{
                        console.log('Error', err.message);
                    }
                });
            }
        }

        const columns = [
            {name: 'authMapNo', header: 'authMapNo', hidden: true},
            {name: 'groupId', header: 'groupId', align: 'center', hidden: true},
            {name: 'rnrNo', header: 'rnrNo', align: 'center', hidden: true},
            {name: 'delete', header: 'Del', minWidth: "20", width: "35", 
                renderer: {
                    type: ButtonInGrid,
                    options: {
                        onButtonClicked,
                        buttonType: "-",
                        buttonCss: "btn btn-sm btn-dark rounded-circle fw-bolder",
                    }
                }
            },
            {name: 'rnrName', header: capitalize(this.state.sideValue)+" Name",},
            {name: 'roleName', header: '권한', align: 'center'},
            {name: 'updateUser', header: '최근변경자', align: 'center'},
            {name: 'updateDate', header: '최근변경일시', align: 'center'},
        ];

        const sideColumns = [
            {name: "no", header: 'No.', hidden: true},
            {name: "name", header: 'Name', align: 'center',},
        ];

        const icon = {
            check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon={faCheckSquare} />,
            uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSquare} />,
            halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon={faCheckSquare} />,
            expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon={faChevronRight} />,
            expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon={faChevronDown} />,
            expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon={faPlusSquare} />,
            collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon={faMinusSquare} />,
            parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon={faFolder} />,
            parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon={faFolderOpen} />,
            leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faFile} />

        }

        return (
            <>
                <div className="page-header">
                    <h3 className="page-title">Authority</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">System Management</li>
                            <li className="breadcrumb-item active" aria-current="page"><Trans>Authority</Trans></li>
                        </ol>
                    </nav>
                </div>
                <div className="row">
                <div className='col-4 container mt-5'>
                    <div className="card card-demo">
                        <div className="card-body">
                            <select className='form-select mb-1' value={this.state.sideValue || ""} onChange={(e) => {e.preventDefault(); this.onChangeResponsibility(e)}}>
                                <option value="">:: Authority Settings ::</option>
                                <option value="menu">Menu</option>
                                <option value="category">Category</option>
                                <option value="brand">Brand</option>
                            </select>
                            {this.state.sideValue === "brand" &&
                                <Grid columns={sideColumns} data={this.state.sideData} rowHeaders={['checkbox']} ref={this.sideRef}/>
                            }
                            {this.state.sideValue !== ("brand" && "") &&
                                <CheckboxTree nodes={treeData} checked={this.state.checked} expanded={this.state.expanded} iconsClass="fa5" showExpandAll={true} checkModel="all"  
                                    icons={icon} onCheck={(checked) => this.setState({ checked })}  onExpand={(expanded) => this.setState({ expanded })}/>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-8 container mt-5'>
                    <div className="card card-demo">
                        <div className="card-body">
                            <div className='d-inline-flex'>
                                <select className='form-select mb-1' value={this.state.role || ""} onChange={(e) => {e.preventDefault(); this.onChangeRole(e)}}>
                                    <option value="">:: Select Authority ::</option>
                                    {
                                        this.state.roles.map((item, index) => {
                                            return (
                                                <option value={item.code} key={index}>{item.codeNm}</option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                            <div className='d-inline-flex float-end'>
                                <button className='btn btn-sm btn-success'onClick={(e)=>this.onSaveAuthority(e)}>
                                    {`Add checked ${capitalize(this.state.sideValue)||"None"} to ${this.state.roleName || "Authority"}`}
                                </button>
                            </div>
                            <Grid columns={columns} data={this.state.authData} ref={this.authRef} rowHeaders={["rowNum"]}></Grid>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}
export default withTranslation()(withRouter(Authority));