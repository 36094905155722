/**
 * This application was developed by YS.Im, HJ.Yoon and GH.Zhang of GIE&S(www.giens.co.kr) at 2022 years.
 */
import React, { Component } from 'react';
import { Link, useLocation} from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans, withTranslation } from 'react-i18next';
import api from '../../CustomAxios';

/**
 * 설명 : 공통 - 페이지 좌측
 *
 * @author		: 임예슬
 * @since 		: 2022.04.15
 * @reference   : 참조 - 
 */
function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		return (
			<Component
				{...props}
				router={{ location }}
			/>
		);
	}

	return ComponentWithRouterProp;
}

class Sidebar extends Component {

	state = {};
    showMenu =[];

	toggleMenuState(menuState) {
		if (this.state[menuState]) {
			this.setState({[menuState] : false});
		} else if(Object.keys(this.state).length === 0) {
			this.setState({[menuState] : true});
		} else {
			Object.keys(this.state).forEach(i => {
				this.setState({[i]: false});
			});
			this.setState({[menuState] : true});
		}
	}


    
	componentDidUpdate(prevProps) {
		if (this.props.router.location !== prevProps.router.location) {
			this.onRouteChanged();
		}
	}

	onRouteChanged() {
		document.querySelector('#sidebar').classList.remove('active');
		Object.keys(this.state).forEach(i => {
			this.setState({[i]: false});
		});

		const dropdownPaths = [
			{path:'/user_management', state: 'membersMenuOpen'},
			{path:'/system_management', state: 'systemsMenuOpen'},
			{path:'/product', state: 'productsMenuOpen'},
			{path:'/order', state: 'ordersMenuOpen'},
			{path:'/inventory', state: 'inventoriesMenuOpen'},
			{path:'/apilink', state: 'apiLinksMenuOpen'},
			{path:'/cssupport', state: 'customerSupportsMenuOpen'},
			{path:'/statistics', state: 'statisticsMenuOpen'},
			{path:'/apimonitoring', state: 'apiMonitoringMenuOpen'},
			{path:'/ptnorder', state: 'partnerOrdersMenuOpen'},
		];

		dropdownPaths.forEach((obj => {
			if (this.isPathActive(obj.path)) {
				this.setState({[obj.state] : true})
			}
		}));
	}

	render () {
		return (
			<nav className="sidebar sidebar-offcanvas" id="sidebar">
				<ul className="nav">
                    <Collapse in={this.isShowYn('/user_management')}>
                        {/* 사용자 관리 */}
                        <li className={ this.isPathActive('/user_management') ? 'nav-item active' : 'nav-item' }>
                            <div className={ this.state.membersMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('membersMenuOpen') } data-toggle="collapse">
                                <span className="menu-title"><div><Trans>User</Trans>&nbsp;<Trans>Management</Trans></div></span>
                                <i className="menu-arrow"></i>
                                <i className="mdi mdi-account-cog menu-icon"></i>
                            </div>
                            <Collapse in={ this.state.membersMenuOpen }>
                                <ul className="nav flex-column sub-menu">
                                    {/* 사용자 관리 */}
                                    <Collapse in={this.isShowYn('/user_management/user')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/user_management/user') ? 'nav-link active' : 'nav-link' } to="/user_management/user"><div><Trans>User</Trans></div></Link></li>
                                    </Collapse>
                                    {/* 입점사 관리 */}
                                    <Collapse in={this.isShowYn('/user_management/store')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/user_management/store') ? 'nav-link active' : 'nav-link' } to="/user_management/store"><div><Trans>Store</Trans></div></Link></li>
                                    </Collapse>
                                    {/* 물류사 관리 */}
                                    <Collapse in={this.isShowYn('/user_management/logitics')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/user_management/logitics') ? 'nav-link active' : 'nav-link' } to="/user_management/logitics"><div><Trans>Logitics</Trans></div></Link></li>
                                    </Collapse>
                                    {/* 멀티쇼핑몰 관리 */}
                                    <Collapse in={this.isShowYn('/user_management/shop')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/user_management/shop') ? 'nav-link active' : 'nav-link' } to="/user_management/shop"><div><Trans>Shop</Trans></div></Link></li>
                                    </Collapse>

                                </ul>
                            </Collapse>
                        </li>
                    </Collapse>

					{/* 시스템 관리 */}
                    <Collapse in={this.isShowYn('/system_management')}>
						<li className={ this.isPathActive('/system_management') ? 'nav-item active' : 'nav-item'}>
							<div className={ this.state.systemsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('systemsMenuOpen') } data-toggle="collapse">
								<span className="menu-title"><div>System Management</div></span>
								<i className="menu-arrow"></i>
								<i className="mdi mdi-cog menu-icon"></i>
							</div>
							<Collapse in={ this.state.systemsMenuOpen }>
								<ul className="nav flex-column sub-menu">
									{/* 메뉴 관리 */}
                                    <Collapse in={this.isShowYn('/system_management/menu')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/system_management/menu') ? 'nav-link active' : 'nav-link' } to="/system_management/menu"><div><Trans>Menu</Trans></div></Link></li>
                                    </Collapse>

									{/* 권한 관리 */}
                                    <Collapse in={this.isShowYn('/system_management/author')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/system_management/authority') ? 'nav-link active' : 'nav-link' } to="/system_management/authority"><div><Trans>Authority</Trans></div></Link></li>
                                    </Collapse>

									{/* 공통코드분류 관리 */}
                                    <Collapse in={this.isShowYn('/system_management/cmn_cls_code')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/system_management/cmn_cls_code') ? 'nav-link active' : 'nav-link' } to="/system_management/cmn_cls_code"><div><Trans>Code Classification</Trans></div></Link></li>
                                    </Collapse>

									{/* 공통코드그룹 관리 */}
                                    <Collapse in={this.isShowYn('/system_management/cmn_code_grp')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/system_management/cmn_code_grp') ? 'nav-link active' : 'nav-link' } to="/system_management/cmn_code_grp"><div><Trans>Code Group</Trans></div></Link></li>
                                    </Collapse>

									{/* 공통코드 관리 */}
                                    <Collapse in={this.isShowYn('/system_management/cmn_code')}>
									<li className="nav-item"> <Link className={ this.isPathActive('/system_management/cmn_code') ? 'nav-link active' : 'nav-link' } to="/system_management/cmn_code"><div><Trans>Code Detail</Trans></div></Link></li>
                                    </Collapse>

									{/* 게시판 관리 */}
                                    <Collapse in={this.isShowYn('/system_management/board_master')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/system_management/board_master') ? 'nav-link active' : 'nav-link' } to="/system_management/board_master"><div><Trans>Board Master</Trans></div></Link></li>
                                    </Collapse>
							
									{/* 환율 관리 */}
                                    <Collapse in={this.isShowYn('/system_management/exchange_rate')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/system_management/exchange_rate') ? 'nav-link active' : 'nav-link' } to="/system_management/exchange_rate"><div><Trans>Exchange Rate</Trans></div></Link></li>
                                    </Collapse>
								</ul>
							</Collapse>
						</li>
                    </Collapse>
					{/* 상품 관리 */}
                    <Collapse in={this.isShowYn('/product')}>
					<li className={ this.isPathActive('/product') ? 'nav-item active' : 'nav-item' }>
						<div className={ this.state.productsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('productsMenuOpen') } data-toggle="collapse">
							<span className="menu-title"><div>Product</div></span>
							<i className="menu-arrow"></i>
							<i className="mdi mdi-cube menu-icon"></i>
						</div>
						<Collapse in={ this.state.productsMenuOpen }>
							<ul className="nav flex-column sub-menu">
								<Collapse in={this.isShowYn('/product/product')}>
                                    <li className="nav-item"> <Link className={ this.isPathActive('/product/product/1') ? 'nav-link active' : 'nav-link' } to="/product/product/1"><div>Product</div></Link></li>
                                </Collapse>
								<Collapse in={this.isShowYn('/product/category')}>
                                    <li className="nav-item"> <Link className={ this.isPathActive('/product/category') ? 'nav-link active' : 'nav-link' } to="/product/category"><div>Category</div></Link></li>
                                </Collapse>
								<Collapse in={this.isShowYn('/product/brand')}>
                                    <li className="nav-item"> <Link className={ this.isPathActive('/product/brand') ? 'nav-link active' : 'nav-link' } to="/product/brand"><div>Brand</div></Link></li>
                                </Collapse>
								<Collapse in={this.isShowYn('/product/manufacturer')}>
                                    <li className="nav-item"> <Link className={ this.isPathActive('/product/manufacturer') ? 'nav-link active' : 'nav-link' } to="/product/manufacturer"><div>Manufacturer</div></Link></li>
                                </Collapse>
								<Collapse in={this.isShowYn('/product/change_info')}>
                                    <li className="nav-item"> <Link className={ this.isPathActive('/product/change_info') ? 'nav-link active' : 'nav-link' } to="/product/change_info"><div>Change All Info</div></Link></li>
                                </Collapse>
                                <Collapse in={this.isShowYn('/product/transmission')}>
                                    <li className="nav-item"> <Link className={ this.isPathActive('/product/transmission') ? 'nav-link active' : 'nav-link' } to="/product/transmission"><div>Transmission</div></Link></li>
                                </Collapse>
								{/* <li className="nav-item"> <Link className={ this.isPathActive('/product/price_monitoring') ? 'nav-link active' : 'nav-link' } to="/product/price_monitoring"><div>Price Monitoring</div></Link></li> */}
							</ul>
						</Collapse>
					</li>
                    </Collapse>

					{/* 주문 관리 */}
                    <Collapse in={this.isShowYn('/order')}>
                        <li className={ this.isPathActive('/order') ? 'nav-item active' : 'nav-item' }>
                            <div className={ this.state.ordersMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('ordersMenuOpen') } data-toggle="collapse">
                                <span className="menu-title"><div>Order</div></span>
                                <i className="menu-arrow"></i>
                                <i className="mdi mdi-cart-check menu-icon"></i>
                            </div>
                            <Collapse in={ this.state.ordersMenuOpen }>
                                <ul className="nav flex-column sub-menu">
                                    <Collapse in={this.isShowYn('/order/order')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/order/order/1') ? 'nav-link active' : 'nav-link' } to="/order/order/1"><div>Order(Target)</div></Link></li>
                                    </Collapse>
                                    <Collapse in={this.isShowYn('/order/order_shop')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/order/order_shop/1') ? 'nav-link active' : 'nav-link' } to="/order/order_shop/1"><div>Order(in Shop)</div></Link></li>
                                    </Collapse>
                                    <Collapse in={this.isShowYn('/order/self_order')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/order/self_order') ? 'nav-link active' : 'nav-link' } to="/order/self_order"><div>Self Order</div></Link></li>
                                    </Collapse>
                                </ul>
                            </Collapse>
                        </li>
                    </Collapse>

					{/* 재고 관리 */}
                    <Collapse in={this.isShowYn('/inventory')}>
                        <li className={ this.isPathActive('/inventory') ? 'nav-item active' : 'nav-item' }>
                            <div className={ this.state.inventoriesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('inventoriesMenuOpen') } data-toggle="collapse">
                                <span className="menu-title"><div>Inventory</div></span>
                                <i className="menu-arrow"></i>
                                <i className="mdi mdi-warehouse menu-icon"></i>
                            </div>
                            <Collapse in={ this.state.inventoriesMenuOpen }>
                                <ul className="nav flex-column sub-menu">
                                    <Collapse in={this.isShowYn('/inventory/inventory')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/inventory/inventory/1') ? 'nav-link active' : 'nav-link' } to="/inventory/inventory/1"><div>Inventory</div></Link></li>
                                    </Collapse>
                                    <Collapse in={this.isShowYn('/inventory/warehouse')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/inventory/warehouse') ? 'nav-link active' : 'nav-link' } to="/inventory/warehouse"><div>Warehouse</div></Link></li>
                                    </Collapse>
                                    <Collapse in={this.isShowYn('/inventory/btob_order')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/inventory/btob_order/1') ? 'nav-link active' : 'nav-link' } to="/inventory/btob_order/1"><div>B2B Order</div></Link></li>
                                    </Collapse>
                                </ul>
                            </Collapse>
                        </li>
                    </Collapse>

					{/* API 연계 관리 */}
                    <Collapse in={this.isShowYn('/apilink')}>
                        <li className={ this.isPathActive('/apilink') ? 'nav-item active' : 'nav-item' }>
                            <div className={ this.state.apiLinksMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('apiLinksMenuOpen') } data-toggle="collapse">
                                <span className="menu-title"><div>API Link</div></span>
                                <i className="menu-arrow"></i>
                                <i className="mdi mdi-link-variant menu-icon"></i>
                            </div>
                            <Collapse in={ this.state.apiLinksMenuOpen }>
                                <ul className="nav flex-column sub-menu">
                                    <Collapse in={this.isShowYn('/apilink/api_link')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/apilink/api_link') ? 'nav-link active' : 'nav-link' } to="/apilink/api_link"><div>API Link</div></Link></li>
                                    </Collapse>
                                </ul>
                            </Collapse>
                        </li>
                    </Collapse>
                    

					{/* 고객지원 관리 */}
                    <Collapse in={this.isShowYn('/cssupport')}>
                        <li className={ this.isPathActive('/cssupport') ? 'nav-item active' : 'nav-item' }>
                            <div className={ this.state.customerSupportsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('customerSupportsMenuOpen') } data-toggle="collapse">
                                <span className="menu-title"><div>Customer Support</div></span>
                                <i className="menu-arrow"></i>
                                <i className="mdi mdi-chat-alert menu-icon"></i>
                            </div>
                            <Collapse in={ this.state.customerSupportsMenuOpen }>
                                <ul className="nav flex-column sub-menu">
                                    <Collapse in={this.isShowYn('/cssupport/board')}>
                                        <li className="nav-item"> <Link className={ this.isPathActive('/cssupport/board/1') ? 'nav-link active' : 'nav-link' } to="/cssupport/board/1">Board</Link></li>
                                    </Collapse>
                                </ul>
                            </Collapse>
                        </li>
                    </Collapse>

					{/* 통계정보 */}
                    <Collapse in={this.isShowYn('/statistics')}>
                        <li className={ this.isPathActive('/statistics') ? 'nav-item active' : 'nav-item' }>
                            <div className={ this.state.statisticsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('statisticsMenuOpen') } data-toggle="collapse">
                                <span className="menu-title"><div>Statistics</div></span>
                                <i className="menu-arrow"></i>
                                <i className="mdi mdi-chart-arc menu-icon"></i>
                            </div>
                            <Collapse in={ this.state.statisticsMenuOpen }>
                                <ul className="nav flex-column sub-menu">
                                <Collapse in={this.isShowYn('/statistics/stts_sales')}>
                                    <li className="nav-item"> <Link className={ this.isPathActive('/statistics/stts_sales') ? 'nav-link active' : 'nav-link' } to="/statistics/stts_sales"><div>Statistics of Sales</div></Link></li>
                                </Collapse>
                                </ul>
                            </Collapse>
                        </li>
                    </Collapse>

                    {/* api 모니터링 */}
                    <Collapse in={this.isShowYn('/apimonitoring')}>
                    <li className={ this.isPathActive('/apimonitoring') ? 'nav-item active' : 'nav-item' }>
                        <div className={ this.state.apiMonitoringMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('apiMonitoringMenuOpen') } data-toggle="collapse">
                            <span className="menu-title"><div>API Monitoring</div></span>
                            <i className="menu-arrow"></i>
                            <i className="mdi mdi-chart-arc menu-icon"></i>
                        </div>
                        <Collapse in={ this.state.apiMonitoringMenuOpen }>
                            <ul className="nav flex-column sub-menu">
                            <Collapse in={this.isShowYn('/apimonitoring/channel')}>
                                <li className="nav-item"> <Link className={ this.isPathActive('/apimonitoring/channel') ? 'nav-link active' : 'nav-link' } to="/apimonitoring/channel"><div>Channel</div></Link></li>
                            </Collapse>
                            <Collapse in={this.isShowYn('/apimonitoring/task_schedule')}>
                                <li className="nav-item"> <Link className={ this.isPathActive('/apimonitoring/task_schedule') ? 'nav-link active' : 'nav-link' } to="/apimonitoring/task_schedule"><div>Task Schedule</div></Link></li>
                            </Collapse>
                            <Collapse in={this.isShowYn('/apimonitoring/dashboard')}>
                                <li className="nav-item"> <Link className={ this.isPathActive('/apimonitoring/dashboard') ? 'nav-link active' : 'nav-link' } to="/apimonitoring/dashboard"><div>Dashboard</div></Link></li>
                            </Collapse>
                            <Collapse in={this.isShowYn('/apimonitoring/statistics')}>
                                <li className="nav-item"> <Link className={ this.isPathActive('/apimonitoring/statistics') ? 'nav-link active' : 'nav-link' } to="/apimonitoring/statistics"><div>Statistics</div></Link></li>
                            </Collapse>
                            </ul>
                        </Collapse>
                    </li>
                </Collapse>


				</ul>
			</nav>
		);
	}

	isPathActive(path) {
		return this.props.router.location.pathname.startsWith(path);
	}

    isShowYn(path){
        const menu = this.showMenu;
        if(menu.includes(path)){
            return true;
        } else {
            return false;
        }
    }

	componentDidMount() {
		this.onRouteChanged();

        const groupId = sessionStorage.getItem("_GROUP_ID");
        api.get(process.env.REACT_APP_DB_HOST + "/common/sideMenu" , {params : {"groupId" : groupId}}).then(res=>{
            if(res.status === 200){
                const showMenu = [];
                for(let i in res.data){
                    showMenu.push(res.data[i].menuUrl)
                }
                this.showMenu = showMenu
                showMenu.forEach((obj=>{
                    this.setState({[obj.state] : true})
                }))
            }
        })

		// add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
		const body = document.querySelector('body');

		document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
			el.addEventListener('mouseover', 
			
			
			function() {
				if(body.classList.contains('sidebar-icon-only')) {
					el.classList.add('hover-open');
				}
			});

			el.addEventListener('mouseout', function() {
				if(body.classList.contains('sidebar-icon-only')) {
					el.classList.remove('hover-open');
				}
			});
		});
	}

}

export default withTranslation() (withRouter(Sidebar));