/**
 * This application was developed by YS.Im, HJ.Yoon and GH.Zhang of GIE&S(www.giens.co.kr) at 2022 years.
 */
import React, { Component, Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Spinner from './components/included/Spinner';
import Authority from './components/system_management/authority';

const Dashboard = lazy(() => import('./components/dashboard/Dashboard'));

const Login = lazy(() => import( './login'));
const Auth = lazy(() => import( './auth'));
const AuthRedirect = lazy(() => import( './auth_redirect'));
const User = lazy(() => import( './components/user_management/user'));
const UserNew = lazy(() => import( './components/user_management/user_regist'));
const UserEdit = lazy(() => import( './components/user_management/user_edit'));
const Store = lazy(() => import( './components/user_management/store'));
const Logitics = lazy(() => import( './components/user_management/logitics'));
const TargetMall = lazy(() => import( './components/user_management/target_mall'));
const Shop = lazy(() => import( './components/user_management/shop'));

const SysMenu = lazy(() => import( './components/system_management/menu'));
const SysAuthor = lazy(() => import( './components/system_management/author'));
const SysAuthorEdit = lazy(() => import( './components/system_management/author_edit'));
const CmnClsCode = lazy(() => import( './components/system_management/cmn_cls_code')); 
const CmnCodeGrp = lazy(() => import( './components/system_management/cmn_code_grp'));
const CmnCode = lazy(() => import( './components/system_management/cmn_code'));
const BoardMaster = lazy(() => import( './components/system_management/board_master'));
const Board = lazy(()=>import('./components/system_management/board'));
const BoardEdit = lazy(()=>import('./components/system_management/board_edit'));
const ExchangeRate = lazy(()=> import('./components/system_management/exchange_rate'))

const ProdProduct = lazy(() => import( './components/product/product'));
const ProdRegist = lazy(() => import( './components/product/product_regist'));
const ProdEdit = lazy(() => import( './components/product/product_edit'));
const ProdCategory = lazy(() => import( './components/product/category'));
const ProdCategoryRegist = lazy(() => import( './components/product/category_regist'));
const ProdBrand = lazy(() => import( './components/product/brand'));
const ProdBrandRegist = lazy(() => import( './components/product/brand_regist'));
const ProdManufacturer = lazy(() => import( './components/product/manufacturer'));
const ProdManufacturerRegist = lazy(() => import( './components/product/manufacturer_regist'));
const ProdChangeInfo = lazy(() => import( './components/product/change_info'));
const ProdPriceMonitoring = lazy(() => import( './components/product/price_monitoring'));
const ProductTransmission = lazy(()=> import('./components/product/transmission'))

const Order = lazy(() => import( './components/order/order'));
const OrderShop = lazy(() => import('./components/order/order_shop'));
const SelfOrder = lazy(() => import( './components/order/self_order'));
const OrderDetail = lazy(() => import( './components/order/order_detail'));
const OrderSuccess = lazy(() => import( './components/order/order_success'));

const Inventory = lazy(() => import( './components/inventory/inventory'));
const Warehouse = lazy(() => import( './components/inventory/warehouse'));
const BtoBOrder = lazy(()=> import('./components/inventory/btob_order'));
const BtoBOrderRegist = lazy(()=> import('./components/inventory/btob_order_regist'));
const BtoBOrderEdit = lazy(()=> import('./components/inventory/btob_order_edit'));

const ApiLink  = lazy(() => import( './components/apilink/api_link')); 

const SttsSales = lazy(() => import( './components/statistics/stts_sales'));

const ApiChannel = lazy(()=>import('./components/apimonitoring/channel'));
const ApiTaskSchedule = lazy(()=>import('./components/apimonitoring/task_schedule'));
const ApiDashboard = lazy(()=>import('./components/apimonitoring/dashboard'));
const ApiStatistics = lazy(()=>import('./components/apimonitoring/statistics'));

const BlankPage = lazy(() => import( './components/general-pages/BlankPage'));

/**
 * 설명 : 라우터
 *
 * @author		: 임예슬
 * @since 		: 2022.04.15
 * @reference   : 참조 - 
 */
class AppRoutes extends Component {
	render () {
		return (
			<Suspense fallback={<Spinner/>}>
				<Routes>
                    <Route exact path="/dashboard" element={ <Dashboard/> } />
                    <Route exact path="/login" element={ <Login/> } />
                    {/* <Route exact path="/cafe24/index" element={ <Auth/> } />
                    <Route exact path="/cafe24/auth" element={ <AuthRedirect/> } /> */}

                    <Route path="/user_management/user" element={ <User/> } />
                    <Route path="/user_management/new" element={ <UserNew/> } />
                    <Route path="/user_management/:userId/edit" element={ <UserEdit/> } />
                    <Route path="/user_management/store" element={ <Store/> } />
                    <Route path="/user_management/logitics" element={ <Logitics/> } />
                    <Route path="/user_management/shop" element={ <Shop/> } />

                    <Route path="/system_management/menu" element={ <SysMenu/> } />
                    {/* <Route path="/system_management/author" element={ <SysAuthor/> } />
                    <Route path="/system_management/:code/author_edit" element={ <SysAuthorEdit/> } /> */}
                    <Route path="/system_management/authority" element={ <Authority/> } />
                    <Route path="/system_management/cmn_cls_code" element={ <CmnClsCode/> } />
                    <Route path="/system_management/cmn_code_grp" element={ <CmnCodeGrp/> } />
                    <Route path="/system_management/cmn_code" element={ <CmnCode/> } />
                    <Route path="/system_management/board_master" element={ <BoardMaster/> } />
                    <Route path="/system_management/exchange_rate" element={ <ExchangeRate/> } />

                    <Route path="/product/product/:page" element={<ProdProduct/>}/>
                    <Route path="/product/new" element={ <ProdRegist/> } />
                    <Route path="/product/:productNo/edit" element={ <ProdEdit/> } />
                    <Route path="/product/category" element={ <ProdCategory/> } />
                    <Route path="/product/category/new" element={ <ProdCategoryRegist/> } />
                    <Route path="/product/brand" element={ <ProdBrand/> } />
                    <Route path="/product/brand/new" element={ <ProdBrandRegist/> } />
                    <Route path="/product/manufacturer" element={ <ProdManufacturer/> } />
                    <Route path="/product/manufacturer/new" element={ <ProdManufacturerRegist/> } />
                    <Route path="/product/change_info" element={ <ProdChangeInfo/> } />
                    <Route path="/product/price_monitoring" element={ <ProdPriceMonitoring/> } />
                    <Route path="/product/transmission" element={ <ProductTransmission/> } />

                    <Route path="/order/order/:page" element={ <Order/> } />
                    <Route path="/order/order_shop/:page" element={ <OrderShop/> } />
                    <Route path="/order/order/:orderNo/detail" element={ <OrderDetail/> } />
                    <Route path="/order/self_order" element={ <SelfOrder/> } />
                    <Route path="/order/self_order/order_success" element={ <OrderSuccess/> } />

                    <Route path="/inventory/inventory/:page" element={ <Inventory/> } />
                    <Route path="/inventory/warehouse" element={ <Warehouse/> } />
                    <Route path="/inventory/btob_order/:page" element={ <BtoBOrder/> } />
                    <Route path="/inventory/btob_order/new" element={ <BtoBOrderRegist/> } />
                    <Route path="/inventory/btob_order/:b2borderNo/edit" element={ <BtoBOrderEdit/> } />

                    <Route path="/apilink/api_link" element={ <ApiLink/> } />

                    <Route path="/cssupport/board/:page" element={ <Board/> } />
                    <Route path="/cssupport/board/:storePostNo/edit" element={ <BoardEdit/> } />

                    <Route path="/statistics/stts_sales" element={ <SttsSales/> } />

                    <Route path="/apimonitoring/channel" element={ <ApiChannel/> } />
                    <Route path="/apimonitoring/task_schedule" element={ <ApiTaskSchedule/> } />
                    <Route path="/apimonitoring/dashboard" element={ <ApiDashboard/> } />
                    <Route path="/apimonitoring/statistics" element={ <ApiStatistics/> } />


                    <Route path="/general-pages/blank-page" element={ <BlankPage/> } />

                    <Route path="/" element={<Navigate to="/dashboard" />}/>
				</Routes>
			</Suspense>
		);
	}
}

export default AppRoutes;