import axios from 'axios';

const api = axios.create({
    headers: {
        Authorization: `Bearer ${sessionStorage.getItem("_JWT_TOKEN")}`,
    }
});
api.interceptors.request.use(
    config => {
    const token = sessionStorage.getItem("_JWT_TOKEN");
    config.headers["Authorization"] = 'Bearer '+token;
    config.headers["Access-Control-Allow-Origin"] = "*";
    return config;
});
export default api;
